<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item> 吹风机管理</el-breadcrumb-item>
      <el-breadcrumb-item>设备详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体卡片视图区域 -->
    <el-card class="box-card">


      <!--二维码画布 包含二维码主体-->
      <canvas id="myCanvas" width="150" height="180" ref="code"
              style="border:1px solid #000000;">
        <span>编号： {{this.$route.query.id}}</span>
      </canvas>

      <!--  二维码主体  -->
      <canvas style="" id="qrImg" ref="code"></canvas>



      <!--    二维码区域 vue-qr   -->
      <div>

        <vue-qr
            style=""
            id="qrImg2"
            :text="imgUrl"
            :size="150"
            :logoSrc="logo"
            :logoScale="0.2">
        </vue-qr>
        <!--    设备编号id    -->


      </div>






    </el-card>


  </div>
</template>

<script>




import VueQr from 'vue-qr';

import QRCode from 'qrcode'

export default {
  name: "Device",


  components:{
    VueQr,
  },

  props: ['value'],
  data() {




    return{


      //平台id
      pingtaiId:'',

      //二维码数据
      imgUrl: 'https://m.cnbolanqi.com/v1/device/show?type=chuifengji&id='+this.$route.query.id,
      logo: require('@/assets/logo.png'),

      howWidth: 8,














    }
  },
  created() {



  },

  mounted() {

    this.getQr()
  },
  methods: {

    get() {

    },


    //生成二维码带文字描述
    getQr() {

      let c = document.getElementById("myCanvas");
      let ctx = c.getContext("2d");
      let pingtaiId = window.sessionStorage.getItem('pingtaiId');

      let code = 'https://m.cnbolanqi.com/blow?'+'pingtai='+ pingtaiId +'&type='+ 'chuifengji'+'&id='+ this.$route.query.id ; // 为安全起见，这里就不展示实际code

      console.log(code);

      //qrcode二维码
      QRCode.toCanvas(this.$refs.code, code);

      let img = document.getElementById("qrImg");
      ctx.drawImage(img,6,6,140,140);

      ctx.font = "18px Arial";
      ctx.fillText("编号："+this.$route.query.id,18,160);

      this.pingtaiId = pingtaiId;

    },








  },


}


</script>

<style lang="less" scoped>

.status {
  display: inline-flex;
  margin: 30px;
}

//#qrImg {
//  width: 200px!important;height: 200px!important;
//}

</style>
